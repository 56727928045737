@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
	--background-color: #DED9C7;
	--secondary-color: #38322C;
	--tertiary-dark: #333333;
}

html,
body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	background: var(--background-color);
	font-family: 'Open Sans', sans-serif;
}

a {
	&,
	&:hover,
	&:active,
	&:visited,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
address {
	font-weight: normal;
	font-style: normal;
	font-size: inherit;
	font-family: inherit;
	margin: 0;
	line-height: normal;
}

.slidedown {
	overflow-y: hidden;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
}

@font-face {
	font-family: 'Geomanist-bold';
	src: url('/fonts/geomanistbold-normal-400-100.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.custom-map-marker {
	z-index: 10;
}
